import { tester } from './tester.js';

const Font = {
	self: document.querySelectorAll('.font'),
	sliders: document.querySelector('.toolbar__sliders'),
	tester: document.querySelector('.font__tester'),
	inputMobile: document.querySelector('.font__input--mobile'),
	input: document.querySelector('.font__input'),
	textbox: document.querySelector('.font__input > [role="textbox"]'),
};

/* create object */
const Strings = {};

function storeStrings() {
	/* get all inputs */
	let textboxes = document.querySelectorAll('.font__input--mobile > [role="textbox"]');

	/* push to object */
	for (const [key, textbox] of Object.entries(textboxes)) {
		let name = textbox.dataset.name;
		Strings[name] = textbox.textContent;
	}

	/* check update */
	// console.log(Strings);
}

function font() {

	/* create an object containing all strings values */
	storeStrings()

	/* apply tester to each font element */
	for(const font of Object.values(Font.self)) {
		console.log(`%cInit font "${font.previousElementSibling.id}"`, 'color:orange');
		tester(font);
	}
}

export { Strings, Font, font };
