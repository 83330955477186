const debug = true;
const body = document.querySelector('#html-body');

function closeModal(modal) {
	/* close animation */
	modal.classList.add('closing');
	/* remove modal after animation */
	setTimeout(function () {
		/* remove modal */
		modal.remove();

		/* set scroll back */
		// if (body.matches('.no-scroll')) {
		// 	body.classList.remove('no-scroll');
		// }
	}, 300);
}

function closeAfterDownload(modal) {
	let trialForm = modal.querySelector('.form-get-trial');
	if (trialForm) {
		trialForm.addEventListener('submit', (event) => {
			if (debug === true) console.log('Submit');
			setTimeout(() => {
				closeModal(modal);
			}, 200);
		});
	}
}

function modal() {
	let modal = document.querySelector('.modal');
	if (modal) {
		if (debug === true) console.log('%cModal found', 'color:orange');

		/* close modal on click */
		let closeBtn = document.querySelectorAll('.js-close-modal');
		for (const btn of Object.values(closeBtn)) {
			btn.addEventListener('click', () => {
				closeModal(modal);
			});
		}

		/* close on form submit */
		closeAfterDownload(modal);
	} else {
		if (debug === true) console.log('%cErr: no modal', 'color:red');
	}
}

export { modal };
