// import { Font } from './font.js';
import { sliders } from './toolbar-sliders.js';
import { buttons } from './toolbar-buttons.js';
import { options } from './toolbar-options.js';
import { reload } from './toolbar-reload.js';

function tester(font) {
	let inputMobile = font.querySelector('.font__input--mobile');
	if (inputMobile) {
		/* reload based on string values */
		reload(font, inputMobile)
	}

	/* options */
	options(font);

	/* sliders */
	sliders(font);

	/* buttons */
	buttons(font);
}

export { tester };
