// import { Font } from './font.js';
const debug = false;

function fontSize(font, value) {
	let input = font.querySelector('.font__input--desktop');
	let textbox = font.querySelector('.font__input--desktop > [role="textbox"]');
	let characters = input.dataset.characters;
	let ratio = (value / characters) * 1000;
	let lines = Math.round(parseInt((100 / ratio) * 2));
	let maxHeight = lines * value - 1.2 * value + 'px';

	function setColumnGap(value, coefficient) {
		// return 10 + coefficient * (value * 2.5) + "px";
		return value + 'px';
	}

	switch (true) {
		case ratio < 10:
			textbox.style.columnWidth = 'auto';
			textbox.style.columnCount = 3;
			textbox.style.columnGap = setColumnGap(value, 0.4);
			break;
		case ratio < 18:
			textbox.style.columnWidth = 'auto';
			textbox.style.columnCount = 2;
			textbox.style.columnGap = setColumnGap(value, 0.8);
			break;
		case ratio < 46:
			textbox.style.maxHeight = maxHeight;
			textbox.style.whiteSpace = 'normal';
			textbox.style.columnWidth = '100vw';
			textbox.style.columnCount = 2;
			textbox.style.columnGap = setColumnGap(value, 0.8);
			input.style.paddingRight = `var(--margin-m)`;
			break;
		default:
			textbox.style.whiteSpace = 'nowrap';
			textbox.style.maxHeight = null;
			textbox.style.columnWidth = 'unset';
			textbox.style.columnCount = 'unset';
			textbox.style.columnGap = 'unset';
			input.style.paddingRight = 0;
			break;
	}
}

function sliders(font) {
	const sliders = font.querySelector('.toolbar__sliders');
	const textbox = font.querySelector('.font__input--desktop > [role="textbox"]');

	if (sliders) {
		if (debug) console.log('Init font range sliders');

		let ranges = sliders.querySelectorAll('[class*="slider__"] input');

		function rangeEffect(range) {
			let value = range.value;
			let label = range.nextElementSibling;
			// console.log(value);

			if (range.id.includes('font-size')) {
				// if (debug) console.log(`range font-size: ${value}px`);
				/* value change */
				textbox.style.fontSize = `${value}px`;
				/* tester change */
				fontSize(font, value);
				/* change label */
				label.innerText = `${value}px`;
			}

			if (range.id.includes('line-height')) {
				// if (debug) console.log(`range line-height: ${value}`);
				/* value change */
				textbox.style.lineHeight = value;
				/* change label */
				label.innerText = value;
			}

			if (range.id.includes('letter-spacing')) {
				// if (debug) console.log(`range letter-spacing: ${value}`);
				/* value change */
				textbox.style.letterSpacing = `${value}px`;
				/* change label */
				label.innerText = `${value}px`;
			}
		}

		for (const range of Object.values(ranges)) {
			range.addEventListener('input', () => {
				rangeEffect(range);
			});
		}
	}
}

export { sliders };
