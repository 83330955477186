import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import { dd, de } from './params.js';

const Header = {
	self: '.header',
	link: '.header__navLink',
};

export const activeHeader = () => {
	gsap.to(Header.self, {
		scrollTrigger: {
			// trigger: '.section:first-child',
			// start: '90% top',
			trigger: '#html-body',
			start: `${window.innerHeight} top`,
			endTrigger: '.footer',
			// markers: true,
			toggleClass: {
				targets: Header.self,
				className: 'header-is-active',
			},
		},
	});
};

function hideHeader() {
	var prevScrollpos = window.pageYOffset;

	function hide() {
		var currentScrollPos = window.pageYOffset;

		gsap.defaults({
			duration: dd,
			ease: de,
		});

		if (prevScrollpos > currentScrollPos) {
			gsap.to(Header.self, {
				yPercent: 0,
			});
		} else {
			gsap.to(Header.self, {
				yPercent: -100,
				duration: dd / 2,
				onComplete: () => {
					if (
						document
							.querySelector(Header.self)
							.matches('.header-mobile.header-is-active')
					) {
						// console.log('closing header');

						/**
						 * 	TODO => clear effect below and make better use of function toggleNav() below
						 *
						 */

						const headerMobile = document.querySelector('.header-mobile');

						if (headerMobile) {
							function resetHeader() {
								const nav =
									headerMobile.querySelector('.header__nav');
								const navItems =
									headerMobile.querySelectorAll(
										'.header__navLink'
									);

								/* reset btn state */
								gsap.set('.nav-wrapper button.btn--primary', {
									clearProps: true,
								});

								/* reset nav btns states */
								gsap.set(navItems, {
									autoAlpha: 0,
									// duration: dd / 3,
									// ease: de,
								});

								/* reset nav panel state */
								gsap.set(nav, {
									height: '0px',
									// duration: dd / 2,
									// ease: de,
									onComplete: () => {
										nav.classList.remove(
											'nav-is-open'
										);
									},
								});
							}

							resetHeader();
						}
					}
				},
			});
		}

		prevScrollpos = currentScrollPos;
	}

	window.addEventListener('scroll', hide);
}

function headerMobile() {
	const headerMobile = document.querySelector('.header-mobile');

	if (headerMobile) {
		const mobile = gsap.timeline();
		// const main = document.querySelector('#main');
		const btn = headerMobile.querySelector('button');
		const nav = headerMobile.querySelector('.header__nav');
		const navItems = headerMobile.querySelectorAll('.header__navLink');
		const navHeight = nav.getBoundingClientRect().height;

		mobile.set(nav, {
			height: '0px',
		});

		mobile.set(navItems, {
			autoAlpha: 0,
		});

		/* toggle */
		// toggleNav(mobile, nav, navItems, navHeight);
		function toggleNav() {
			if (nav.matches('.nav-is-open')) {
				mobile.to(navItems, {
					autoAlpha: 0,
					duration: dd / 3,
					ease: de,
				}).to(
					nav,
					{
						height: '0px',
						duration: dd / 2,
						ease: de,
						onComplete: () => {
							nav.classList.remove('nav-is-open');
						},
					},
					`-=${dd / 3}`
				);
			} else {
				mobile.to(nav, {
					height: `${navHeight}px`,
					duration: dd,
					ease: 'elastic',
				}).to(
					navItems,
					{
						autoAlpha: 1,
						duration: dd / 3,
						ease: de,
						onComplete: () => {
							nav.classList.add('nav-is-open');
						},
					},
					`-=${dd / 2}`
				);
			}
		}

		/* open on click button */
		btn.addEventListener('click', toggleNav);

		/* close on click item */
		for (const item of Object.values(navItems)) {
			item.addEventListener('click', toggleNav);
		}

		/* close on click "outside" */
		// main.addEventListener('click', toggleNav);
	}
}

function scrollToItem() {
	const navLinks = document.querySelectorAll(Header.link);

	for (const link of Object.values(navLinks)) {
		link.addEventListener('click', (event) => {
			/* prevent jump */
			event.preventDefault();

			gsap.to(window, {
				scrollTo: {
					y: `${link.hash}.anchor-link`,
					offsetY: `-1`,
				},
				duration: dd,
				ease: de,
			});
		});
	}
}

function header() {
	/* hide header on scroll down */
	hideHeader();

	/* show mobile nav */
	headerMobile();

	/* active header state */
	activeHeader();

	/* on homepage only */
	if (document.querySelector('.body.page-home')) {
		/* scroll to front item */
		scrollToItem();
	}
}

export { header };
