import { gsap } from 'gsap';
import { dd, de } from '../params.js';
const debug = false;

/**  Notes
 *
 *   Convert hex to rgb
 *   [https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb]
 *
 *   Select text color based on bg
 *   [https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area]
 *
 *
 */

function align(btns, textbox) {
	for (const btn of Object.values(btns)) {
		btn.addEventListener('click', () => {
			let value = btn.dataset.value;
			textbox.style.textAlign = value;
			if (debug) console.log(`text-align: ${value}`);
		});
	}
}

function transform(btns, textbox) {
	for (const btn of Object.values(btns)) {
		btn.addEventListener('click', () => {
			let value = btn.dataset.value;
			textbox.style.textTransform = value;
			if (debug) console.log(`text-transform: ${value}`);
		});
	}
}

function ligature(btns, textbox) {
	for (const btn of Object.values(btns)) {
		btn.addEventListener('click', () => {
			let value = btn.dataset.value;
			textbox.style.fontVariantLigatures = value;
			if (debug) console.log(`font-variant-ligatures: ${value}`);
		});
	}
}

function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

function changeBgColor(container, colors, current) {
	/* change background */
	container.style.background = `var(--color-${current.dataset.value})`;
	/* get "next" color - but it's previous */
	let prev = current.previousElementSibling;

	if (prev) {
		/* set "next" active */
		prev.classList.add('active');
	} else {
		/* or set the first color active */
		colors[colors.length - 1].classList.add('active');
	}

	/* remove current color */
	current.classList.remove('active');
}

function changeTextColor(container, textbox, current) {
	/* get style */
	let styles = getComputedStyle(document.documentElement);
	/* get property */
	let hex = styles.getPropertyValue(`--color-${current.dataset.value}`);
	/* convert hex to rgb */
	let rgb = hexToRgb(hex);
	/* parse rgb values */
	let parsedR = parseInt(rgb['r']) * 299;
	let parsedG = parseInt(rgb['g']) * 587;
	let parsedB = parseInt(rgb['b']) * 114;
	/* convert to brigthness */
	let brightness = Math.round((parsedR + parsedG + parsedB) / 1000);
	/* select text color */
	let textColor = brightness > 125 ? 'black' : 'white';

	/* set text color */
	textbox.style.color = `var(--color-${textColor})`;

	/* set font title color too */
	let title = container.querySelector('.font__title');
	title.style.color = `var(--color-${textColor})`;

	if (debug) console.log(`color variable: ${current.dataset.value}`);
	if (debug) console.log(`color variable value: ${hex}`);
	if (debug) console.log(`[r: ${rgb['r']}, g: ${rgb['g']}, b: ${rgb['b']}]`);
	if (debug) console.log(`text color: ${textColor}`);
}

function colorSwitcher(btn, textboxes) {
	let colors = btn.querySelectorAll('.color');
	let current = btn.querySelector('.color.active');

	for (const textbox of textboxes) {
		let container = textbox.closest('.font');
	
		if (container) {
			/* change background colors */
			changeBgColor(container, colors, current);
			/* change text color */
			changeTextColor(container, textbox, current);
		}
	}
}

function rotateIcon(btn) {
	gsap.to(btn, {
		rotate: '+=180',
		duration: dd / 2,
		ease: de,
	});
}

function color(btns, textboxes) {
	for (const btn of Object.values(btns)) {
		btn.addEventListener('click', () => {
			let value = btn.dataset.value;

			/* switch colors */
			colorSwitcher(btn, textboxes);

			if (value === 'bw') {
				rotateIcon(btn);
			}

			if (debug) console.log(`color: ${value}`);
		});
	}
}

function toggleActiveButtons(group) {
	let btns = group.querySelectorAll('button[class*="button__"]');

	function clear(btns) {
		for (const btn of Object.values(btns)) {
			btn.classList.remove('active');
		}
	}

	function toggle(btn) {
		if (btn.matches('.active')) {
			btn.classList.remove('active');
		} else {
			btn.classList.add('active');
		}
	}

	for (const btn of Object.values(btns)) {
		btn.addEventListener('click', () => {
			/* clear all first */
			clear(btns);

			/* active current */
			toggle(btn);
		});
	}
}

function buttons(font) {
	const buttons = font.querySelector('.toolbar__buttons');
	const textboxDesktop = font.querySelector('.font__input--desktop > [role="textbox"]');
	const textboxes = font.querySelectorAll('.font__input > [role="textbox"]');
	// const textbox = font.querySelector('.font__input > [role="textbox"]');

	if (buttons) {
		if (debug) console.log('Init font buttons');

		let buttonGroup = buttons.querySelectorAll('div[class*="button__"]');

		function groupEffect(group) {
			let btns = group.querySelectorAll('button[class*="button__"]');

			/* desktop only */
			if (group.matches('.button__align')) {
				if (debug) console.log(`button group: align`);
				align(btns, textboxDesktop);
			}

			/* desktop only */
			if (group.matches('.button__ligature')) {
				if (debug) console.log(`button group: ligature`);
				ligature(btns, textboxDesktop);
			}

			/* both */
			if (group.matches('.button__transform')) {
				if (debug) console.log(`button group: transform`);
				for (const textbox of textboxes) {
					transform(btns, textbox);
				}
			}

			/* both */
			if (group.matches('.button__color')) {
				if (debug) console.log(`button group: color`);
				// for (const textbox of textboxes) {
					color(btns, textboxes);
				// }
			}
		}

		for (const group of Object.values(buttonGroup)) {
			// if (debug) console.log(group.classList);

			/* select effect */
			groupEffect(group);

			/* toggle active button on click */
			toggleActiveButtons(group);
		}
	}
}

export { buttons };
