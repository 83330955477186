/* viewport hack */
function setVh() {
	function getVh() {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	getVh();
	window.addEventListener('resize', getVh);

	console.log('%cInit mobile hack', 'color:orange');
}

export { setVh };
