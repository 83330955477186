import { gsap } from 'gsap';

const debug = true;

function scaleUp(item) {
	gsap.to(item, {
		scale: 1.5,
		zIndex: 1,
		duration: 1,
		ease: 'elastic',
	});
}

function scaleDown(item) {
	gsap.to(item, {
		scale: 1,
		zIndex: 0,
		duration: 1,
		ease: 'elastic',
	});
}

function buttons() {
	const primary = document.querySelectorAll(':not(.header-mobile) .btn--primary');
	const action = document.querySelectorAll('.btn--action');
	// let btns = [primary, action]

	for (const btn of primary) {
		// console.log(`${btn}`);

		btn.addEventListener('mouseenter', () => {
			scaleUp(btn);
		});

		btn.addEventListener('mouseleave', () => {
			scaleDown(btn);
		});
	}

	for (const btn of Object.values(action)) {
		// console.log(`${btn} action`);

		btn.addEventListener('mouseenter', () => {
			scaleUp(btn);
		});

		btn.addEventListener('mouseleave', () => {
			scaleDown(btn);
		});
	}
}

function labels() {
	const labels = document.querySelectorAll('.font__labels .label');

	for (const label of Object.values(labels)) {
		gsap.to(label, {
			rotation: '360',
			ease: 'none',
			duration: 5,
			repeat: -1,
		});

		label.addEventListener('mouseenter', () => {
			scaleUp(label);
		});

		label.addEventListener('mouseleave', () => {
			scaleDown(label);
		});
	}
}

function modalAnimations() {
	if (debug === true) console.log(`%cInit modal buttons animations`, 'color:orange');

	const modal = document.querySelector('#modal');
	if (modal) {
		const primary = document.querySelectorAll('.btn--primary');
		const action = document.querySelectorAll('.btn--action');

		for (const btn of primary) {
			// console.log(`${btn}`);

			btn.addEventListener('mouseenter', () => {
				scaleUp(btn);
			});

			btn.addEventListener('mouseleave', () => {
				scaleDown(btn);
			});
		}

		for (const btn of Object.values(action)) {
			// console.log(`${btn} action`);

			btn.addEventListener('mouseenter', () => {
				scaleUp(btn);
			});

			btn.addEventListener('mouseleave', () => {
				scaleDown(btn);
			});
		}
	}
}

function animations() {
	console.log(`%cInit animations`, 'color:orange');

	/* buttons */
	buttons();

	/* labels */
	labels();
}

export { animations, modalAnimations };
