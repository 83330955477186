import { gsap } from 'gsap';
// import { Font } from './font.js';
import { dd, de } from '../params.js';

const debug = false;

function toggle(select) {
	let btn = select.querySelector('.custom-select__button');

	function toggleOptions(btn) {
		if (!btn.matches('.open')) {
			btn.classList.add('open');
		} else {
			btn.classList.remove('open');
		}
	}

	btn.addEventListener('click', () => {
		if (debug) console.log('Open options');
		toggleOptions(btn);
	});
}

function weight(select, textbox) {
	// let titles = select.querySelectorAll('.select__title');
	let options = select.querySelectorAll('.select__option');

	function resetTitles() {
		let selected = select.querySelector('.select__title.selected');
		if (selected) selected.classList.remove('selected');
	}

	function selectOption(opt) {
		let value = opt.dataset.value;
		let selector = `.select__title[data-value="${value}"]`;
		let match = select.querySelector(selector);
		if (match) {
			/* show current title */
			match.classList.add('selected');

			/* change font weight */
			// if (value == 'Regular') {
			// 	textbox.style.fontWeight = 'normal';
			// } else {
			// 	textbox.style.fontWeight = value.toLowerCase();
			// }

			const tester = gsap.timeline();

			tester.to(textbox, {
				autoAlpha: 0,
				duration: dd / 2,
				ease: de,
				onComplete: () => {
					textbox.style.fontWeight = Number(value);
				}
			}).to(textbox, {
				autoAlpha: 1,
				duration: dd,
				ease: de,
			}, "+=0.25")

			// textbox.style.fontWeight = Number(value);

			if (debug) console.log(Number(value));
			// if (debug) console.log(value.toLowerCase());
			if (debug) console.log(textbox.style.fontWeight);
		}
	}

	for (const opt of Object.values(options)) {
		opt.addEventListener('click', () => {
			/* reset titles first */
			resetTitles();

			/* select current option/title */
			selectOption(opt);

			/* close on click */
			let btn = select.querySelector('.custom-select__button');
			btn.classList.remove('open');
		});
	}
}

function options(font) {
	const options = font.querySelector('.toolbar__options');
	const textboxes = font.querySelectorAll('.font__input > [role="textbox"]');

	if (options) {
		if (debug) console.log('Init font options');

		let selects = options.querySelectorAll('.custom-select');

		function optionEffect(select) {
			if (select.matches('.toolbar__weight')) {
				if (debug) console.log(`custom select: weight`);
				for (const textbox of textboxes) {
					weight(select, textbox);
				}
			}
		}

		for (const select of Object.values(selects)) {
			/* open select on click */
			toggle(select);

			/* set active option */
			optionEffect(select);
		}
	}
}

export { options };
