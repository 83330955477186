const debug = true;

function resetGroup(group) {
	if (debug === true) console.log(`reset group`);

	/* hide group */
	if (group.matches('.visible')) {
		group.classList.remove('visible');
	}

	/* reset visible state */
	let visibles = group.querySelectorAll('.hidden.visible');

	for (const item of visibles) {
		item.classList.remove('visible');
	}

	/* reset checked state */
	let checked = group.querySelectorAll('input:checked');

	for (const item of checked) {
		item.checked = false;
	}

	/* reset required state */
	let required = group.querySelectorAll('input:required');

	for (const item of required) {
		item.required = false;
	}

	/* reset the select value */
	/* hide the contact button */
}

function buyFont(form) {
	/* buyer => self */
	let self = form.querySelector('[id="buying-for-myself"]');
	let selfGroup = form.querySelector('.group-personnal');
	let student = form.querySelector('[id="student"]');
	let school = form.querySelector('[id="name-school"]');

	/* buyer => client */
	let client = form.querySelector('[id="buying-for-client"]');
	let clientGroup = form.querySelector('.group-client');
	let buyer = form.querySelector('[id="name-buyer"]');
	let volume = form.querySelector('.custom-select');

	if (self) {
		if (debug === true) console.log(`buyer => self`);

		self.addEventListener('click', () => {
			/* reset client group */
			resetGroup(clientGroup);

			/* show personnal buyer options */
			if (!selfGroup.matches('.visible')) {
				if (debug === true) console.log(`show buyer "self" options`);
				selfGroup.classList.add('visible');
			}
		});

		/* if the person is a student, ask for school's name */
		student.addEventListener('click', () => {
			/* show the input school name */
			if (!school.matches('.visible')) {
				school.classList.add('visible');
			}

			/* make it required */
			if (school.required !== true) {
				school.required = true;
			}
		});
	}

	if (client) {
		if (debug === true) console.log(`buyer => client`);

		client.addEventListener('click', () => {
			/* reset self group */
			resetGroup(selfGroup);

			/* show client buyer options */
			if (!clientGroup.matches('.visible')) {
				if (debug === true) console.log(`show buyer "client" options`);
				clientGroup.classList.add('visible');
			}

			/* make buyer required */
			if (buyer.required != true) {
				buyer.required = true;
			}
		});

		/* if the volume is custom -> show the contact button */
	}
}

function form() {
	const modal = document.querySelector('#modal');
	if (modal) {
		let buyFontForm = modal.querySelector('.form-buy-font');
		if (buyFontForm) {
			if (debug === true) console.log(`%cInit modal form`, 'color:orange');

			/* form "buy font" conditionnals */
			buyFont(buyFontForm);
		}
	}
}

export { form };
