import { Strings } from './font.js';

const debug = false;

const Params = {
	// min: 3,
	min: 5,
	// max: 25,
	max: 45,
};

function randLength() {
	/* availables length steps */
	// const lengths = [Params.min, 5, 8, 10, 15, 20, Params.max];
	// const lengths = [Params.min, 5, 10, 15, 20, 25, 30, Params.max];
	// const lengths = [Params.min, 5, 15, 25, Params.max];
	const lengths = [Params.min, 15, 25, 35, Params.max];
	/* return random length */
	return lengths[Math.floor(Math.random() * lengths.length)];
}

/* truncate string -- ES6 version */
function shortenStringByWord(textbox, randLength) {
	/* get corresponding string content */
	for (const [name, storedString] of Object.entries(Strings)) {
		if (name === textbox.dataset.name) {
			// if (debug == true) console.log(`matching string found in stored strings: ${name}`);
			/* keep var to escape scope */
			var str = storedString; 
		}
	}
	// let str = textbox.innerText;
	// let str = textbox.textContent;
	// if (debug == true) console.log(str);
	/* remove whitespace */
	const array = str.trim().split(' ');
	// if (debug == true) console.log(array);
	/* place ellipsis */
	const ellipsis = array.length > randLength ? '...' : '';
	// if (debug == true) console.log(ellipsis);
	/* keep fixed start values for the moment */
	const randStart = Math.floor(Math.random() * (12 - 0 + 1) + 0);
	// if (debug == true) console.log(randStart);
	/* add min value to avoid an output of 1 word only */
	const stringStart = randStart + Params.min < randLength ? randStart : 0;
	// if (debug == true) console.log(stringStart);
	/* create new string */
	const newString = array.slice(stringStart, randLength).join(' ') + ellipsis;
	// if (debug == true) console.log(newString);
	/* return new string */
	textbox.innerText = newString;
	// console.log(randLength);
}

function selectFontSize(textbox, randLength) {
	switch (true) {
		case randLength <= Params.min:
			textbox.style.fontSize = '3.5rem';
			break;
		case randLength <= 5:
			textbox.style.fontSize = '2.5rem';
			break;
		case randLength <= 15:
			textbox.style.fontSize = '2rem';
			break;
		case randLength < 30:
			textbox.style.fontSize = '1.5rem';
			break;
		case randLength < Params.max:
			textbox.style.fontSize = '1rem';
			break;
		default:
			textbox.style.fontSize = '1rem';
			break;
	}
}

function reloadValue(textbox) {
	/* init random value */
	const randValue = randLength();
	// if (debug == true) console.log(randValue);
	// if (debug == true) console.log(textbox.innerText);
	// if (debug == true) console.log(textbox.textContent);

	/* add ellipsis on random-length string */
	shortenStringByWord(textbox, randValue);

	/* select corresponding font-size */
	selectFontSize(textbox, randValue);
}

function reload(font, inputMobile) {
	if (debug == true) console.log('%cInit reload function', 'color: orange');

	/* get input mobile textbox */
	let textboxMobile = inputMobile.querySelector('[role="textbox"]');
	// if (debug == true) console.log(textboxMobile);

	/* create an object containing all strings values */
	// storeStrings();

	/* init value */
	reloadValue(textboxMobile);

	const reloadBtn = font.querySelector('.toolbar__reload-font-size .btn');
	/* reload on click */
	if (reloadBtn) {
		reloadBtn.addEventListener('click', () => {
			// if (debug == true) console.log('click');
			reloadValue(textboxMobile);
		});
	}
}

export { storeStrings, reload };
