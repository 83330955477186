import LazyLoad from 'vanilla-lazyload';
import Swiper from 'swiper/swiper-bundle.min.js';

const debug = false;

/* basic options */
const options = {
	loop: true,
	pagination: {
		enabled: true,
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true,
	},
	grabCursor: true,
	navigation: {
		enabled: true,
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on: {
		// LazyLoad swiper images after swiper initialization
		afterInit: (swiper) => {
			new LazyLoad({
				container: swiper.el,
				cancel_on_exit: false,
			});
		},
	},
};

/* catalogue options */
const catalogueOptions = {
	loop: true,
	breakpoints: {
		320: {
			slidesPerView: 1,
		},
		768: {
			slidesPerView: 3,
		},
		1600: {
			slidesPerView: 4,
		},
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true,
	},
	grabCursor: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on: {
		// LazyLoad swiper images after swiper initialization
		afterInit: (swiper) => {
			new LazyLoad({
				container: swiper.el,
				cancel_on_exit: false,
			});
		},
	},
};

/* shop options */
const shopOptions = {
	// loop: false,
	// breakpoints: {
	// 	320: {},
	// 	768: {},
	// 	1600: {},
	// },
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true,
	},
	grabCursor: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on: {
		// LazyLoad swiper images after swiper initialization
		afterInit: (swiper) => {
			new LazyLoad({
				container: swiper.el,
				cancel_on_exit: false,
			});
		},
	},
};

function swiper() {
	if (debug == true) console.log(`%cInit Swiper(s)`, 'color:orange');

	/* catalogue slider params */
	const catalogue = document.querySelector('.swiper-catalogue');
	if (catalogue) {
		if (debug == true) console.log(`Adding custom params to Catalogue Swiper`);
		let swiper = new Swiper(catalogue, catalogueOptions);
	}

	/* shop slider params */
	const shop = document.querySelector('.swiper-shop');
	if (shop) {
		if (debug == true) console.log(`Adding custom params to Shop Swiper`);
		let swiper = new Swiper(shop, shopOptions);
	}

	/* catalogue slider params */
	let medias = document.querySelectorAll('.media .swiper');
	if (medias.length > 0) {
		if (debug == true) console.log(`Adding custom params to media Swiper`);
		for (const media of Object.values(medias)) {
			new LazyLoad({
				elements_selector: '.swiper-lazy',
				unobserve_entered: true,
				callback_enter: function (swiperElement) {
					new Swiper(swiperElement, options);
				},
			});
		}
	}
}

export { swiper };
