import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import { dd, de } from '../params.js';

const Font = {
	link: '.font-card__link',
};

function scrollToCatalogue() {
	let showreel = document.querySelector('.front-item-showreel');
	let explore = showreel.querySelector('.btn a');

	explore.addEventListener('click', (event) => {
		/* prevent jump */
		event.preventDefault();
		gsap.to(window, {
			scrollTo: {
				y: `${explore.hash}.anchor-link`,
				offsetY: `-1`,
			},
			duration: dd,
			ease: de,
		});
	});
}

function scrollToItem() {
	const fontLinks = document.querySelectorAll(Font.link);

	for (const link of Object.values(fontLinks)) {
		link.addEventListener('click', (event) => {
			/* prevent jump */
			event.preventDefault();
			gsap.to(window, {
				scrollTo: {
					y: `${link.hash}.anchor-link`,
					offsetY: `-1`,
				},
				duration: dd,
				ease: de,
			});
		});
	}
}

function catalogue() {
	/* expliore button */
	scrollToCatalogue();

	/* scroll to font */
	scrollToItem();
}

export { catalogue };
