import 'htmx.org';
import './components/htmx.js';
// import { gsap } from "gsap";
import { setVh } from './components/helpers.js';
import { animations, modalAnimations } from './components/animations.js';
import { lazyload } from './components/lazyload.js';
import { header } from './components/header.js';
import { swiper } from './components/swiper.js';
import { catalogue } from './components/front/catalogue.js';
import { font } from './components/font/font.js';
import { form } from './components/form.js';
import { modal } from './components/htmx/modal.js';

const body = document.querySelector('#html-body');

if (document.querySelector('html')) setVh();
if (document.querySelector('.header')) header();
if (document.querySelector('.lazy')) lazyload();
if (document.querySelector('#main')) animations();
if (document.querySelector('.swiper')) swiper();
if (document.querySelector('.catalogue')) catalogue();
if (document.querySelector('.font')) font();
if (document.querySelector('.modal .form')) form();

/* Htmx Events */
document.body.addEventListener('htmx:afterSwap', function (evt) {
	const htmxElement = evt.detail.elt;
	// console.log(htmxElement.id);

	const htmxDebug = true;

	// if (htmxDebug === true) {
	const modalBtn = document.querySelectorAll('.btn--trial');
	for (const btn of Object.values(modalBtn)) {
		btn.addEventListener('htmx:afterOnLoad', () => {
			setTimeout(() => {
				/* hide scroll */
				// if (!body.matches('.no-scroll')) {
				// 	body.classList.add('no-scroll');
				// }

				/* reload animations() for modal buttons */
				// animations();
				// modalAnimations();

				console.log('Opening modal');
			}, 10);
		});
	}
	// }

	if (htmxElement.id == 'html-body') {
		console.log(`htmx:afterSwap "${htmxElement.id}"`);
		if (document.querySelector('.modal')) {
			/* show/hide modal */
			modal();

			/* modal buttons animations */
			modalAnimations();

			/* form conditionnals */
			// form();
		} 
	}
});
